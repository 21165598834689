<template>
  <div>
    <div class="container" align="left">
      <div class="d-inline-block mt-5">
        <h4 class="mb-4"><strong>이메일 무단 수집 거부</strong></h4>
        <p>
          본 웹사이트에 게시된 이메일 주소가 전자우편 수집 프로그램이나 그 밖의 기술적 장치를 이용하여 무단으로 수집되는
          것을 거부하며, 이를 위반시 정보통신망법에 의해 형사 처벌 됨을 유념하시기 바랍니다.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Rejectionemail',
  mounted() {
    this.$store.dispatch('Display/changeName', {name: '이메일 무단 수집 거부'});
  },
};
</script>

<style scoped lang="scss">
p {
  word-break: keep-all;
}
</style>
